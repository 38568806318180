/*
 * The SCSS variables
 */

/* sidebar */

$sidebar-width: 260px !default; /* the basic width */
$sidebar-width-large: 300px !default; /* screen width: >= 1650px */

/* other framework sizes */

$topbar-height: 3rem !default;
$search-max-width: 210px !default;
$footer-height: 5rem !default;
$footer-height-mobile: 6rem !default; /* screen width: < 850px */
$main-content-max-width: 1250px !default;
$bottom-min-height: 35rem !default;
$base-radius: 0.5rem;

/* syntax highlight */

$code-font-size: 0.85rem !default;

/* fonts */

$font-family-base: 'Source Sans Pro', 'Microsoft Yahei', sans-serif;
$font-family-heading: Lato, 'Microsoft Yahei', sans-serif;
